
@keyframes bounce {
    0%, 10%, 20%, 30%   {
        transform: translateY(0);
        -webkit-transform: translateY(0);
    }
    5%  {
        transform: translateY(-20px);
        -webkit-transform: translateY(-20px);

    }
    15%  {
        transform: translateY(-10px);
        -webkit-transform: translateY(-10);

    }
    25% {
        transform: translateY(-5px);
        -webkit-transform: translateY(-5); 
    }

}


@keyframes rotate {
    0% { 
        transform: rotate(0); 
        -webkit-transform: rotate(0);
    }
    100% { 
        transform: rotate(1440deg);
        -webkit-transform: rotate(1440deg);
    }
}