@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=VT323&display=swap');

* {
  box-sizing: border-box;
}

body {
  /* Fix for mobile bars taking up vh */
  /* position: fixed; */
  height: 100%;
  width: 100%;
  
  margin: 0;
  font-family: "Press Start 2p", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4 {
  font-family: "Press Start 2p", "VT323"
}

p {
  font-family: "VT323";
}

a, a:visited {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.noScrollBar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.noScrollBar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.crossBrowserSticky {
  position: -webkit-sticky;
  position: sticky;
  
}