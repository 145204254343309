@keyframes spinTop {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-35deg);
    }
}

@keyframes spinBottom {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(35deg);
    }
  }

.pacman-top {
    animation: spinTop .5s infinite linear;
}

.pacman-bottom {
    animation: spinBottom .5s infinite linear;
  }
